<template>
  <div class="shabda-form">
    <div v-if="currentIssue">
      <p class="standout">The submission deadline for <b>{{ currentIssue.title }}</b> is midnight (UK time) on {{ displayDate(new Date(currentIssue.submission_deadline_date)) }}</p>
    </div>
    <div v-if="successfullySubmitted">
      <h2>
      Your {{ successfulSubmissionType }} contribution has been submitted
      </h2>

      <p><a :href="editLetterLink" :title="'edit my ' + successfulSubmissionType"><b>edit my {{ successfulSubmissionType }}</b></a></p>
      <p><i>You can submit additional notices and eulogies at any time this month.</i></p>

      <div class="buttons">
      <button v-for="contributionType in contributionTypes"
              :key="contributionType.name"
              type="button"
              class="btn btn-primary btn-action"
              @click="reset(); formData.contribution_type = contributionType.name; changeStep(2);">
        <span>
          <font-awesome-icon v-if="contributionType.icon" :icon="contributionType.icon" />
            {{ contributionType.label }}
        </span>
      </button>
      </div>

    </div>
    <div v-if="errorMsg" class="error">
      <p>
        Sorry we could not complete the submission at this time:
      </p>
      <ul v-if="errorMsg.constructor == Array">
        <li v-for="msg in errorMsg" :key="msg">{{ msg }}</li>
      </ul>
      <div v-else>{{ errorMsg }}</div>
    </div>
    <Loading v-if="loading" :loading="loading" msg="loading" />
    <div v-if="!successfullySubmitted" v-show="!complete && !loading" class="form">
      <div v-show="formData.step === 1" class="step-1">
        <h2 v-if="ordername" class="welcome">Welcome to Shabda, {{ ordername }}!</h2>
        <template v-if="!hasSubmittedLetter">
        <p>We’re looking forward to hearing from you.</p>
        <p>You can submit one letter and additional notices and eulogies at any time this month.</p>
        </template>
        <template v-else>
        <p>You have already contributed a letter this month: <a :href="editLetterLink" title="view or edit your letter" class="edit-letter"><b>view or edit your letter</b></a></p>
        <p>You can submit additional notices and euolgies at any time this month.</p>
        </template>
        <div class="buttons">
        <button v-for="contributionType in contributionTypes"
                :key="contributionType.name"
                type="button"
                class="btn btn-primary btn-action"
                @click="formData.contribution_type = contributionType.name; changeStep(2);">
          <span>
            <font-awesome-icon v-if="contributionType.icon" :icon="contributionType.icon" />
              {{ contributionType.label }}
          </span>
        </button>
        </div>
      </div>

      <div v-show="formData.step === 2" class="step-2">
        <p>
          <b>You are submitting a {{ formData.contribution_type }}</b> <a href="#back" @click.prevent="changeStep(1)" class="link-order">> Change contribution type</a>
        </p>

        <div v-if="isSaving.isSaving" class="saving">saving...</div>

        <LetterForm v-show="formData.contribution_type === 'letter'"
            @send="preview"
        />

        <NoticeForm v-show="formData.contribution_type === 'notice'"
            @send="preview"
        />

        <EulogyForm v-show="formData.contribution_type === 'eulogy'"
            @send="preview"
        />

      </div>

      <div v-if="formData.step === 3" class="step-3">
        <p>
          <b>You are submitting a {{ formData.contribution_type }}</b> <a href="#back" @click.prevent="changeStep(2)" class="link-order">> edit contribution</a>
        </p>

        <div class="preview">
          <component :is="contributionPreviewComponent"
            :contribution="{ contributionItem : formData }"
            :showStatus="false"
          />
        </div>

        <p>
          <i>Please check the contribution above before submitting.</i>
          <br />
          <button type="submit" class="btn btn-success btn-action" @click="send">submit contribution</button>
        </p>
      </div>
    </div>

      <p class="note">
      Please note that all contributions will be lightly moderated according to the best traditions of Shabda. We may be in touch about content or the length of your submission.
      </p>
      <p class="note">
      If you have any questions about your contribution or anything else connected to Shabda, please <b>contact the compiler.</b>
      </p>
  </div>
</template>

<script>
import Sugar from 'sugar-date'
import shabdaApi from '../../api/shabda-api.js'
import appApi from '../../api/app-api.js'
import Loading from '../Loading.vue'
import LetterForm from './LetterForm.vue'
import NoticeForm from './NoticeForm.vue'
import EulogyForm from './EulogyForm.vue'
import ShabdaLetterContribution from '../organisms/ShabdaLetterContribution.vue'
import ShabdaNoticeContribution from '../organisms/ShabdaNoticeContribution.vue'
import ShabdaEulogyContribution from '../organisms/ShabdaEulogyContribution.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faFlagAlt, faPrayingHands } from '@fortawesome/pro-light-svg-icons'
import store from '../../store/shabda'

library.add(faEnvelope, faFlagAlt, faPrayingHands)

const contributionTypes = [
  {
    name: 'letter',
    label: '+ letter report',
    icon: ['fal', 'envelope']
  },
  {
    name: 'notice',
    label: '+ notice',
    icon: ['fal', 'flag-alt']
  },
  {
    name: 'eulogy',
    label: '+ eulogy',
    icon: ['fal', 'praying-hands']
  }
]

export default {
  components: {
    Loading,
    LetterForm,
    NoticeForm,
    EulogyForm,
    ShabdaLetterContribution,
    ShabdaNoticeContribution,
    ShabdaEulogyContribution
  },
  props: {
    editLetterLink: {
      type: String,
      default: '/order/shabda-my-contributions'
    }
  },
  data() {
    return {
      successfullySubmitted: false,
      errorMsg: null,
      loading: false,
      complete: false,
      ordername: null,
      hasSubmittedLetter: false,
      currentIssue: null,
      successfulSubmissionType: null
    }
  },
  created() {
    store.load()
    this.ordername = appApi.appData().ordername
    this.checkLetters()

    this.loading = true

    shabdaApi.fetchCurrentIssue()
      .then(res => {
        this.currentIssue = res.data || null
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    contributionPreviewComponent() {
      if (this.formData.contribution_type === 'letter') {
        return 'ShabdaLetterContribution'
      }

      if (this.formData.contribution_type === 'notice') {
        return 'ShabdaNoticeContribution'
      }

      if (this.formData.contribution_type === 'eulogy') {
        return 'ShabdaEulogyContribution'
      }

      return ''
    },
    isSaving() {
      return store.isSaving
    },
    formData() {
      return store.contributionForm
    },
    content() {
      return store.contributionForm.content
    },
    contributionTypes() {
      const availableContributionTypes = contributionTypes

      if (this.hasSubmittedLetter) {
        availableContributionTypes.shift()
      }

      return availableContributionTypes
    }
  },
  methods: {
    checkLetters() {
      shabdaApi.fetchAll('letters')
        .then(data => {
          if (data.letters && data.letters.length > 0) {
            this.hasSubmittedLetter = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    changeStep(no) {
      store.contributionForm.step = no
      store.persist()
    },
    preview() {
      this.changeStep(3)
    },
    send() {
      this.loading = true
      this.errorMsg = null
      this.successfullySubmitted = false

      shabdaApi.contribute(store.contributionForm)
        .then(data => {
          this.successfullySubmitted = true
          this.complete = true
          this.successfulSubmissionType = this.formData.contribution_type
          store.clear()
          this.checkLetters()
        })
        .catch(err => {
          this.errorMsg = err.errors || err.msg || err
        })
        .finally(() => {
          this.loading = false
        })
    },
    reset() {
      this.checkLetters()
      this.successfullySubmitted = false
      this.complete = false
    },
    displayDate(date, format = '%a, %d %b %Y') {
      return Sugar.Date.format(date, format)
    }
  }
}
</script>

<style lang="scss">
.shabda-form {
  max-width: 720px;

  label {
    display: block;
  }

  .form form {
    margin: 1rem 0;
    padding-bottom: 2rem;
  }

  label {
    display: block;
    font-size: 22px;
    letter-spacing: 0.69;
    margin: 33px 0 14px 0;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }

  input {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  }

  .tiptap {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    margin: 0.5rem 0;
  }

  .btn-action {
    background-color: #006AA3;
    border: #006AA3 1px solid;
    color: #fff;
    margin-right: 30px;
    margin-top: 33px;
    padding: 4px 30px 4px 16px;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    cursor: pointer;
  }

  .link-order {
    margin-left: 0.25rem;
    color: #006AA3 !important;
  }

  .sr-only {
    display: none;
  }

  .editor {
    margin-top: 33px;
  }

  p {
    margin-bottom: 0.75rem;
  }

  h3 {
    margin-bottom: 1.25rem;
  }

  .preview {
    margin: 2rem 0;
  }

  .note {
    font-style: italic;
  }

  p {
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 30px;
    letter-space: 0.94;
    margin-bottom: 2rem;
  }

  .buttons {
    margin-bottom: 33px;
  }

  .step-2 {
    position: relative;
  }

  .saving {
    position: absolute;
    top: 2.1rem;
    left: 0;
  }
}
</style>
